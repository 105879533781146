import React, {useEffect, useState} from 'react'
import axios from '../../axiosInstance';
import {
    Autocomplete,
    Box, Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal, Pagination, PaginationItem,
    Select,
    TextField,
} from "@mui/material";
import ListCard from "../../components/ListCard";
import MemberModal from "../permit/components/MemberModal";
import {useParams} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import {titleState} from "../../store/titleStore";
import DownloadMember from "./compotents/DownloadMember";
import {loadingState} from "../../store/loadingStore";
import {colorState} from "../../store/colorStore";

const initSelectBox = {
    '대행사': '',
    '지사': '',
    '총판': '',
    '대리점': '',
    '가맹점': '',
}
export default function Agency() {
    const memberInfo = useRecoilValue(memberState);
    const [memberList, setMemberList] = useState([]);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    // const [title, setTitle] = useState('');
    const [keyword, setKeyword] = useState({category: 'memName', word: ''});
    const {parameter} = useParams();
    const setTitle = useSetRecoilState(titleState);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const [searchToken, setSearchToken] = useState(null);
    const [chargeInfo, setChargeInfo] = useState({});
    const [searchMenu, setSearchMenu] = useState([])
    const [selectedCate, setSelectedCate] = useState(memberInfo.memCode)
    const [categoryList, setCategoryList] = useState({})
    const [selectedLV, setSelectedLV] = useState(initSelectBox);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isFired, setIsFired] = useState('N')
    const setLoadingState = useSetRecoilState(loadingState);
    const color = useRecoilValue(colorState);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                search();
            }
        };
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };

    }, []);

    useEffect(() => {
        let titlePrefix = '';
        if (parameter === '가맹점') {
            titlePrefix = isFired === 'N' ? '사용 ' : '해지 ';
        } else {
            setIsFired('N')
        }
        setTitle(`${titlePrefix}${parameter} 목록`);

        if (isSearch) {
            performSearch();
        } else if (selectedCate !== '' && selectedCate !== 'all') {
            searchCate(selectedCate);
        } else {
            getMemberList();
        }
    }, [parameter, page, searchToken, isFired]);

    useEffect(() => {
        setPage(1);
        setIsSearch(false);
        setSearchToken(null);
        setKeyword({category: 'memName', word: ''});
        setSearchCate();
        getSelectBox(memberInfo.memCode);
    }, [parameter]);

    useEffect(() => {
        if (selectedCate === '' || selectedCate === 'all') {
            return;
        }
        getSelectBox(selectedCate);
        // searchCate(selectedCate);
    }, [selectedCate]);


    useEffect(() => {
        if (data && data.chargeInfo) {
            const transformed = data.chargeInfo.reduce((acc, item) => {
                acc[item.chargeRank] = item;
                return acc;
            }, {});
            setChargeInfo(transformed);
        }
    }, [data]);


    const searchCate = async (memCode) => {

        await setLoadingState(true);

        await axios.get(`/api/member/memberList/${memCode}/${parameter}?page=${page}&isFired=${isFired}`).then((response) => {
            setMemberList(response.data.content);
            setPageData(response.data);

        }).catch(error => {});

        await setLoadingState(false);

    }

    const getSelectBox = (memCode) => {
        axios.get(`/api/member/searchCate/${memCode}`).then(response => {
            setCategoryList({...categoryList, ...response.data});
        }).catch(error => {})
    }

    const setSearchCate = () => {

        let menulist = []

        switch (memberInfo.memRole) {
            case '본사':
                menulist = ['대행사', '지사', '총판', '대리점', '가맹점']
                break;
            case '지사':
                menulist = ['총판', '대리점', '가맹점']
                break;
            case '총판':
                menulist = ['대리점', '가맹점']
                break;
            case '대리점':
                menulist = ['가맹점']
                break;
        }
        modifySearchMenu(parameter, menulist);
    }

    const modifySearchMenu = (parameter, menulist) => {
        const index = menulist.indexOf(parameter);
        if (index !== -1) {
            setSearchMenu(menulist.slice(0, index));
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    const getMemberList = async () => {
        await setLoadingState(true);

        await axios.get(`/api/member/memberList/${memberInfo.memCode}/${parameter}?page=${page}&isFired=${isFired}`).then((response) => {
            setMemberList(response.data.content);
            setPageData(response.data);
        }).catch((error) => {

        });

        await setLoadingState(false);

    };
    const handleOpen = (data) => {
        setData(data)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        isSearch ? search() : getMemberList()
    };

    const searchKeywordHandler = (event) => {
        setKeyword({...keyword, [event.target.name]: event.target.value});
    }


    const search = () => {
        setIsSearch(true);
        setPage(1);
        setSearchToken(Date.now());
    }


    const performSearch = async () => {
        await setLoadingState(true);

        const payload = keyword.category === 'memName' ?
            {
                [keyword.category]: keyword.word
            }
            :
            {
                memberDetail: {[keyword.category]: keyword.word}
            };
        await axios.post(`/api/member/memberList/${selectedCate}/${parameter}?page=${page}&isFired=${isFired}`, payload).then((response) => {
            setMemberList(response.data.content);
            setPageData(response.data);
        }).catch(error => {})

        await setLoadingState(false);
    }

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const resetSearch = () => {
        window.location.reload();
        // setPage(1)
        // setIsSearch(false);
        // setSearchToken(Date.now());
        // setKeyword((prev) => ({
        //     category: 'memName',
        //     word: ''
        // }));
    }

    const selectCate = (e, menu) => {
        const keys = Object.keys(initSelectBox);
        const paramIndex = keys.indexOf(menu);
        let newSelectedLV = {...selectedLV, [menu]: e.target.value}; // 선택된 cate.memCode를 해당 메뉴의 값으로 저장
        keys.slice(paramIndex + 1).forEach(key => {
            newSelectedLV[key] = '';
        });
        setSelectedLV(newSelectedLV);
        setSelectedCate(e.target.value);
        setSearchKeyword({...searchKeyword, selectedMem: e.target.value}); // 선택된 cate.memCode를 검색어로 저장
    }

    const resetButton = (e, v) => {
        if (v === '') {
            window.location.reload();
        }

    }

    const isFiredHandler = (e) => {
        setIsFired(e.target.value)
    }




    return (
        <>
            <Container sx={{marginTop: 0}}>
                {parameter === '가맹점' &&
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Button value={'N'} onClick={isFiredHandler}
                                    size="small" variant="contained" fullWidth
                                    style={{backgroundColor: isFired === 'N' ? 'gray' : color, marginTop: '15px'}}>
                                사용 가맹점
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button value={'Y'} onClick={isFiredHandler}
                                    size="small" variant="contained" fullWidth
                                    style={{backgroundColor: isFired === 'Y' ? 'gray' : color, marginTop: '15px'}}>
                                해지 가맹점
                            </Button>
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={1} justifyContent="flex-end">

                    {searchMenu.map((menu, idx) => (
                        menu !== '가맹점' &&
                        <Grid key={menu.memCode + idx || idx} item xs={2} container alignItems="flex-end"
                              justifyContent="center">
                            <FormControl fullWidth variant="standard">
                                {selectedLV[menu] === '' && <InputLabel>{menu}</InputLabel>}
                                <Autocomplete
                                    sx={{marginTop: 2}}
                                    options={categoryList[menu] || []}
                                    getOptionLabel={(option) => option.memName}
                                    value={categoryList[menu]?.find(cate => cate.memCode === selectedLV[menu]) || null}
                                    onChange={(event, newValue) => {
                                        selectCate({target: {value: newValue ? newValue.memCode : ''}}, menu);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        // newInputValue는 새로운 입력 값입니다.
                                        // 만약 x 버튼을 클릭하여 입력을 지웠다면, newInputValue는 빈 문자열('')이 됩니다.
                                        // 이곳에서 원하는 로직을 수행하실 수 있습니다.
                                        resetButton(event, newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="standard"/>}
                                />
                            </FormControl>
                        </Grid>
                    ))}

                    {(memberInfo.memRole === '본사' && parameter === '대행사') &&
                        <DownloadMember></DownloadMember>
                    }

                    <Grid item xs={2} container alignItems="center" justifyContent="center">
                    <FormControl fullWidth variant="standard">
                            <InputLabel>항목선택</InputLabel>
                            <Select onChange={searchKeywordHandler} name="category" defaultValue={'memName'}>
                                <MenuItem value="memName">회원명</MenuItem>
                                <MenuItem value="shopTell">연락처</MenuItem>
                                <MenuItem value="shopOwner">대표자명</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justifyContent="center">
                        <TextField onKeyDown={handleKeyDown} onChange={searchKeywordHandler} fullWidth label={'검색어'}
                                   name={'word'}
                                   variant="standard"
                                   value={keyword.word}
                        />
                    </Grid>
                    <Grid item xs={1} container alignItems="center" justifyContent="center">
                        <Button onClick={search} size="small" variant="contained" fullWidth
                                style={{backgroundColor: color, marginTop: '15px'}}>
                            검색
                        </Button>
                    </Grid>
                    <Grid item xs={1} container alignItems="center" justifyContent="center">
                        <Button onClick={resetSearch} size="small" variant="contained" fullWidth
                                style={{backgroundColor: color, marginTop: '15px'}}>
                            초기화
                        </Button>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <ListCard
                        pageData={pageData}
                        handleOpen={handleOpen}
                        memberList={memberList}
                        setSearchToken={setSearchToken}
                    ></ListCard>
                </Grid>

                <Grid item xs={12}>
                    <Pagination
                        count={pageData.pageSize > 0 ? Math.ceil(pageData.total / pageData.pageSize) : 0}
                        page={pageData.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                        onChange={onPageChange}
                        size="medium"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "15px 0",
                        }}
                        renderItem={(item, idx) => (
                            <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                        )}
                    />
                </Grid>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            minWidth: '300px',
                            width: '45%',
                            maxHeight: '90%', // 총 높이의 80%를 넘지 않음
                            overflowY: 'auto', // 세로 스크롤바 필요시 나타남
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 3,
                        }}
                    >
                        <MemberModal parameter={parameter} getMemberList={getMemberList} chargeInfo={chargeInfo}
                                     handleClose={handleClose} data={data}></MemberModal>
                    </Box>
                </Modal>
            </Container>
        </>
    )
}
