import {
    AccordionDetails,
    Box, Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import axios from '../../../axiosInstance';
import {BasicInfoModal} from "./BasicInfoModal";
import {useLocation} from "react-router-dom";
import ImageViewer from 'react-simple-image-viewer';
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";
import EditBasicInfo from "../../agency/compotents/EditBasicInfo";
import TerminalJoinInfo from "./TerminalJoinInfo";
import EditTerminalInfo from "../../agency/compotents/EditTerminalInfo";
import {titleState} from "../../../store/titleStore";
import AddMoreInfo from "./AddMoreInfo";
import ShopChargeInfo from "./ShopChargeInfo";
import {colorState} from "../../../store/colorStore";

const chargeinit = {
    non: '0',
    exempt: '0',
    small1: '0',
    small2: '0',
    small3: '0',
    normal: '0',
};

export default function MemberModal({parameter, data, handleClose, getMemberList}) {

    const title = useRecoilValue(titleState);
    const memberInfo = useRecoilValue(memberState);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [pgList, setPgList] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const [formData, setFormData] = useState({
        catId: [{}],
    });
    const location = useLocation();
    const isPermitRoute = location.pathname === '/permit';
    const [openAccordions, setOpenAccordions] = useState([]); // 여러 아코디언의 상태를 배열로 관리
    const [charge, setCharge] = useState({...chargeinit})
    const [isEdit, setIsEdit] = useState(false);
    const [selectedPG, setSelectedPG] = useState('PG001');
    const [pgCharge, setPgCharge] = useState({...chargeinit});
    const [originCharge, setOriginCharge] = useState({...chargeinit})
    const [selectedPGName, setSelectedPGName] = useState('')
    const [isEditTerminal, setIsEditTerminal] = useState(false);
    const color = useRecoilValue(colorState);


    useEffect(() => {
        getSelectList(data.memRole);
        getPgList();
    }, []);

    useEffect(() => {
        if (formData.highMemCode) {
            getSumCharge(formData.highMemCode);
        } else if (data.memberDetail.highMemCode) {
            getSumCharge(data.memberDetail.highMemCode);
        }

    }, [formData.highMemCode]);

    useEffect(() => {
    }, [formData]);

    useEffect(() => {
        if (formData.pgCode) {
            getPGCharge(formData.pgCode);
            getSumCharge(formData.highMemCode);
        }
    }, [formData.pgCode]);

    useEffect(() => {
        if (selectedPG) {
            getPGCharge(selectedPG);
            getSumCharge(data.memberDetail.highMemCode);
            if (pgList.length > 0) {
                setSelectedPGName(pgList.find(pg => pg.pgCode === selectedPG).pgName);
            }
        }
    }, [selectedPG]);


    const getPGCharge = (pgCode) => {
        axios.get(`/api/member/charge/PG/${pgCode}`).then(response => {
            setPgCharge(response.data);
        });
    }

    const checkTid = (tid) => {
        axios.get(`/api/admin/checkTid/${tid}`).then(response => {
            return response.data;
        });
    }

    const getSumCharge = (highMemCode) => {

        if (!highMemCode) {
            return
        }
        axios.get(`/api/member/myCharge/${highMemCode}/${selectedPG}`).then(response => {
            setCharge(response.data);
            setOriginCharge(response.data);
        });
    }
    const isEditHandler = () => {
        setIsEdit(!isEdit);
    }

    const isEditTerminalHandler = () => {
        setIsEditTerminal(!isEditTerminal);
    }

    const closeModal = () => {
        setFormData({});
        handleClose();
    }

    const handleChange = (e) => {

        const field = e.target.name;
        const selectedMemCode = e.target.value;

        if (field === 'highMemCode') {
            const selectedObject = selectList.find(list => list.memCode === selectedMemCode);
            if (!selectedObject) {
                return;
            }
            setFormData({...formData, [field]: selectedObject.memCode});
            return
        } else if (field === 'pgCode') {
            const selectedObject = pgList.find(list => list.pgCode === selectedMemCode);
            setFormData({...formData, [field]: selectedObject.pgCode});
            return
        } else if (Object.keys(charge).includes(field)) {
            // const adjustedValue = (parseFloat(e.target.value) - parseFloat(charge[field])).toFixed(2);
            setFormData({...formData, [field]: e.target.value});
            return;
        }
        setFormData({...formData, [field]: e.target.value});
    };

    const getPgList = () => {
        axios.get('/api/PG/all').then(response => {
            setPgList(response.data);
        })
    }

    const getSelectList = (memRole) => {
        axios.get(`/api/member/memRole/${memRole}`).then(response => {
            setSelectList(response.data);
        })
    }

    const permit = () => {

        if (title === '가맹점') {
            if (Array.isArray(formData.catId)) {
                formData.catId.forEach(id => {
                    if (checkTid(id.catId) < 1) {
                    } else {
                        alert(`TID ${id.catId}가 중복됩니다.`);

                    }
                });
            }
        }
        const url = isPermitRoute ? `/api/admin/permit/${data.memCode}` : `/api/member/${data.memCode}`
        const payload = {...formData};
        axios.patch(url, payload).then(() => {
            if (isPermitRoute) {
                handleClose();
                alert('승인 완료')
            } else {
                handleClose();
                alert('회원정보 수정 완료')
            }
        }).catch(() => {
            if (isPermitRoute) {
                alert('승인에 실패 하였습니다.')
            } else {
                alert('회원정보 수정 실패')
            }
        });
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.attachedName}`;
    });

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);


    const images = data.joinFiles.map(file => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.attachedName}`;
    });


    const handleAccordion = (index) => {
        setOpenAccordions(prevOpen => {
            if (prevOpen.includes(index)) {
                // 이미 열린 아코디언을 닫습니다.
                return prevOpen.filter(i => i !== index);
            } else {
                // 새 아코디언을 추가합니다.
                return [...prevOpen, index];
            }
        });
    };

    const resetPW = () => {
        axios.get(`/api/admin/resetPw?memCode=${data.memCode}`).then(() => {
            alert('비밀번호 초기화 완료');
        }).catch(err => {
            alert('비밀번호 초기화 실패');
        });
    }

    const chargeInfoHandler = (e) => {
        setSelectedPG(e.target.value);
    }

    const updateIsFired = (isFired) => {
        axios.patch(`/api/member/updateIsFired/${data.memCode}/${isFired}`).then(response => {
            if (isFired === 'N') {
                alert('가맹점 해지 취소 완료');
            } else {
                alert('가맹점 해지 완료');
            }
            handleClose();
        });
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const handleFileChange = (event, originName) => {
        const file = event.target.files[0];
        setSelectedFile({ file, originName });

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleFileUpload = (originName) => {
        if (selectedFile && selectedFile.originName === originName) {
            const file = selectedFile.file;
            const newFile = new File([file], originName, { type: file.type });

            const formData = new FormData();
            formData.append('file', newFile);
            formData.append('originName', originName);
            axios.patch(`/api/admin/updateFile/${data.memCode}`, formData)
                .then(response => {
                    alert("첨부파일 수정 완료")
                    // 성공 시 로직 추가
                })
                .catch(error => {
                    alert("첨부파일 수정 실패")
                    // 실패 시 로직 추가
                });
        }
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Typography
                    variant="h5"
                    style={{
                        borderBottom: "2px solid black",
                    }}
                >
                    회원 상세 정보
                </Typography>
                {isEdit ?
                    <EditBasicInfo getMemberList={getMemberList} isEditHandler={isEditHandler} handleClose={handleClose}
                                   data={data}></EditBasicInfo>
                    :
                    <BasicInfoModal data={data}></BasicInfoModal>
                }
                {!isEdit &&
                    <Box display="flex" justifyContent="center" alignItems="center" marginTop="10px" width="100%">
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                <Button variant="contained"
                                        onClick={isEditHandler}
                                        style={{marginTop: "20px", marginBottom: "20px", backgroundColor: color}}>
                                    회원 정보 수정
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={resetPW} variant="contained"
                                        style={{marginTop: "20px", marginBottom: "20px", backgroundColor: color}}>
                                    비밀번호 초기화
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                }


                {data.memRole === '가맹점' ?
                    isEditTerminal ?
                        <EditTerminalInfo handleClose={handleClose} isEditTerminalHandler={isEditTerminalHandler}
                                          data={data}/>
                        :
                        <TerminalJoinInfo isEditTerminalHandler={isEditTerminalHandler} data={data}/>
                    : <></>
                }


                <Typography
                    variant="h5"
                    style={{
                        borderBottom: "2px solid black",
                        marginTop: "20px",
                    }}
                >
                    첨부 서류 확인
                </Typography>
                <Table aria-label="simple table">
                    <TableBody>
                        {data.joinFiles.map((file, index) => (
                            <React.Fragment key={file.memCode + index}>
                                <TableRow>
                                    <TableCell style={{ width: '80%' }} align="center" onClick={() => handleAccordion(index)}>
                                        {file.originName}
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} align="center">
                                        <Button variant="contained" component="label"
                                                size="small"
                                                sx={{
                                                    marginX: 1,
                                                    backgroundColor: color,
                                                }}
                                        >
                                            수정
                                            <input
                                                type="file"
                                                hidden
                                                onChange={(event) => handleFileChange(event, file.originName)}
                                            />
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} align="center">
                                        <Button
                                            variant="contained"
                                            sx={{
                                                marginX: 1,
                                                backgroundColor: color,
                                            }}
                                            size="small"
                                            onClick={() => handleFileUpload(file.originName)}
                                            disabled={!selectedFile || selectedFile.originName !== file.originName}
                                        >
                                            업로드
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                {openAccordions.includes(index) && (
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <AccordionDetails>
                                                <img
                                                    src={imageURL(file)}
                                                    alt={file.originName}
                                                    style={{ maxWidth: '100%', height: 'auto' }}
                                                    onClick={() => openImageViewer(index)}
                                                />
                                            </AccordionDetails>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {selectedFile && selectedFile.originName === file.originName && (
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <AccordionDetails>
                                                <img
                                                    src={preview}
                                                    alt="Preview"
                                                    style={{ maxWidth: '100%', height: 'auto' }}
                                                />
                                            </AccordionDetails>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
                <>
                    <Typography
                        variant="h5"
                        style={{
                            borderBottom: "2px solid black",
                            marginTop: "20px",
                        }}
                    >
                        추가 정보
                    </Typography>
                    {(memberInfo.memRole !== '본사' && data.memRole === '가맹점') ?
                        <ShopChargeInfo data={data}/>
                        :
                        <AddMoreInfo data={data} selectedPG={selectedPG}
                                     chargeInfoHandler={chargeInfoHandler}
                                     pgList={pgList} memberInfo={memberInfo}
                                     selectList={selectList} handleChange={handleChange}
                                     formData={formData} permit={permit} parameter={parameter}
                                     charge={originCharge} selectedPGName={selectedPGName} isPermitRoute={isPermitRoute}
                                     getMemberList={getMemberList} setFormData={setFormData}
                        ></AddMoreInfo>
                    }
                </>
            </TableContainer>

            <Box display="flex" justifyContent="center" alignItems="center" marginTop="10px" width="100%">
                <Grid container justifyContent="center" spacing={2}>
                    {memberInfo.role === 'ROLE_ADMIN' &&
                        <Grid item>
                            {
                                isPermitRoute ?
                                    <Button variant="contained"
                                            onClick={permit}
                                            style={{
                                                marginTop: "20px",
                                                marginBottom: "20px",
                                                backgroundColor: color
                                            }}>
                                        승인
                                    </Button>
                                    :
                                    <Button variant="contained"
                                            onClick={permit}
                                            style={{
                                                marginTop: "20px",
                                                marginBottom: "20px",
                                                backgroundColor: color
                                            }}>
                                        수정
                                    </Button>
                            }
                        </Grid>
                    }
                    <Grid item>
                        <Button onClick={closeModal} variant="contained"
                                style={{marginTop: "20px", marginBottom: "20px", backgroundColor: color}}>
                            닫기
                        </Button>
                    </Grid>

                    {(data.memRole === '가맹점' && data.isFired === 'N' && memberInfo.memRole === '본사') &&
                        <Grid item>
                            <Button onClick={() => {
                                updateIsFired('Y')
                            }} variant="contained"
                                    style={{marginTop: "20px", marginBottom: "20px", backgroundColor: color}}>
                                가맹점 해지
                            </Button>
                        </Grid>
                    }
                    {(data.memRole === '가맹점' && data.isFired === 'Y' && memberInfo.memRole === '본사') &&
                        <Grid item>
                            <Button onClick={() => {
                                updateIsFired('N')
                            }} variant="contained"
                                    style={{marginTop: "20px", marginBottom: "20px", backgroundColor: color}}>
                                가맹점 해지취소
                            </Button>
                        </Grid>
                    }

                </Grid>
            </Box>

            {
                isViewerOpen && (
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                )
            }
        </>
    )
        ;
}
