import React, {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import {titleState} from "../../store/titleStore";
import axios from "../../axiosInstance";
import {
    Grid,
    Pagination,
    PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import cancelIcon from "../../images/cancel.png";
import applyIcon from "../../images/apply.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MSearch from "./components/MSearch";

const MbileTableCell = (props) => {

    return (
        <TableCell
            {...props}
            sx={{...props.sx, fontSize: '12px', padding: 0.5, paddingY: 1}}
        >
            {props.children}
        </TableCell>
    );
};

const MobilePayList = () => {

    const memberInfo = useRecoilValue(memberState);
    const [payData, setPayData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const setTitle = useSetRecoilState(titleState);
    const [initState, setInitState] = useState(true);
    const [rowVisibility, setRowVisibility] = useState({});


    useEffect(() => {
        setTitle('결제 목록')
    }, []);


    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const openReceipt = (tid) => {
        const url = `https://pgapi.korpay.com/api/receipt?tid=${tid}`;
        const options = 'width=500,height=900'; // 팝업 창의 크기와 위치 설정
        window.open(url, '_blank', options);
    }

    const toggleRowVisibility = (id) => {
        setRowVisibility(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    return (
        <>
            <MSearch setInitState={setInitState} initState={initState} isSearch={isSearch} setIsSearch={setIsSearch}
                    page={page} setPage={setPage} setPayData={setPayData} setPageData={setPageData}></MSearch>

            <TableContainer component={Paper} sx={{padding: 0.5}}>
                <Table sx={{fontSize: '8px'}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                        </TableRow>
                        <TableRow>
                            <MbileTableCell align="center"
                                            sx={{padding: 0, fontSize: '10px', width: '20%'}}
                                            component="th">
                                승인일시
                            </MbileTableCell>
                            <MbileTableCell sx={{ width: '10%' }} align="center" component="th">
                                승인
                            </MbileTableCell>
                            {memberInfo.roleRank < 6 &&
                                <MbileTableCell align="center" sx={{width: '30%'}} component="th">
                                가맹점
                            </MbileTableCell>}
                            <MbileTableCell align="center" component="th">
                                승인번호
                            </MbileTableCell>
                            <TableCell sx={{ fontSize: '9px', padding: 0 }} align="center" component="th">
                                승인금액<br/>
                                {pageData.sum && pageData.sum['ORIGIN_AMT_SUM'].toLocaleString()}
                            </TableCell>
                            <MbileTableCell align="center" component="th">
                                상세
                            </MbileTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payData.map((data, idx) => (
                            <>
                                <TableRow key={idx} align="center"
                                          sx={{
                                              '&:hover': {
                                                  backgroundColor: 'rgba(0, 0, 0, 0.04)', // 호버 시 원하는 색상으로 변경
                                              },
                                          }}>
                                    <MbileTableCell align="center">
                                        {data.PAY_DATE.split(' ')[0]} <br/> {data.PAY_DATE.split(' ')[1]}
                                    </MbileTableCell>
                                    <MbileTableCell align="center">
                                        {data.CANCEL_YN === 'Y' ?
                                            <img src={cancelIcon} alt="cancel icon"
                                                 style={{height: '13px', width: 'auto'}}/>
                                            :
                                            <img src={applyIcon} alt="cancel icon"
                                                 style={{height: '13px', width: 'auto'}}/>
                                        }
                                    </MbileTableCell>

                                    {memberInfo.roleRank < 6 && (
                                        <MbileTableCell align="center">
                                            {data.LV6_NAME}
                                        </MbileTableCell>
                                    )}

                                    <MbileTableCell
                                        align="center"
                                        onClick={() => openReceipt(data.TID)}
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            '&:hover': {
                                                textDecoration: 'none', // 호버 시 밑줄 제거 (선택사항)
                                            }
                                        }}
                                    >
                                        {data.APP_NO}
                                    </MbileTableCell>
                                    <MbileTableCell align="center">
                                        {data.CANCEL_YN == 'Y' ? '-' : ''} {data.ORIGIN_AMT}
                                    </MbileTableCell>
                                    <MbileTableCell align="center">
                                        <ExpandMoreIcon onClick={() => toggleRowVisibility(idx)}></ExpandMoreIcon>
                                    </MbileTableCell>
                                </TableRow>
                                {rowVisibility[idx] && (
                                    <>
                                        <TableRow sx={{ paddingX: 0 }}>
                                            <MbileTableCell align="center" sx={{ width: '20%' }}>
                                                카드사
                                            </MbileTableCell>
                                            <MbileTableCell align="center" sx={{ width: '40%' }} colspan={2}>
                                                카드번호
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                할부
                                            </MbileTableCell>
                                            <MbileTableCell align="center" colspan={2}>
                                                정산금액
                                            </MbileTableCell>
                                        </TableRow>
                                        <TableRow sx={{ borderBottom: '2px' }}>
                                            <MbileTableCell align="center">
                                                {data.CARD_NAME}
                                            </MbileTableCell>
                                            <MbileTableCell align="center" colspan={2} sx={{ fontSize: '9px' }}>
                                                {data.CARD_NO}
                                            </MbileTableCell>
                                            <MbileTableCell align="center">
                                                {data.QUOTA}
                                            </MbileTableCell>
                                            <MbileTableCell align="center" colspan={2}>
                                                {data.CANCEL_YN == 'Y' ? '-' : ''} {data.FINAL_AMT}
                                            </MbileTableCell>
                                        </TableRow>
                                    </>
                                )}
                            </>

                        ))
                        }
                    </TableBody>
                </Table>
                <Grid item xs={12}>
                    <Pagination
                        count={pageData.pageSize > 0 ? Math.ceil(pageData.total / pageData.pageSize) : 0}
                        page={pageData.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                        onChange={onPageChange}
                        size="medium"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "15px 0",
                        }}
                        renderItem={(item, idx) => (
                            <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                        )}
                    />
                </Grid>
            </TableContainer>
        </>
    );
};

export default MobilePayList;