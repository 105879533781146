import React from 'react';
import {AppBar, Toolbar, Typography, IconButton, Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {titleState} from "../../../store/titleStore";
import {useNavigate} from "react-router-dom";
import axios, {getCookie} from '../../../axiosInstance';
import {memberState} from "../../../store/memberStore";
import {colorState} from "../../../store/colorStore";
import {Container} from "@mui/material";
const logo = `/images/${process.env.REACT_APP_LOGO_IMG}`;

const Header = ({toggleSidebar}) => {
    const color = useRecoilValue(colorState);
    const title = useRecoilValue(titleState);
    const navigate = useNavigate();
    const setMemberInfo = useSetRecoilState(memberState);

    const pathHandler = (path) => {
        navigate(path);
    };

    const logoutHandler = () => {

        const refreshToken = getCookie('refreshToken');
        axios.delete('/api/auth/logout', { data: { refreshToken: refreshToken } }).then(response => {
            deleteToken();
            setMemberInfo({
                memCode: '',
                memRole: '',
                memId: '',
                memName: '',
                role: '',
                roleRank: '',
            })
            navigate('/');
        }).catch(error => {
        });
    }

    const deleteToken = () => {
        document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    return (
        <AppBar position="fixed" sx={{backgroundColor: color, color: 'white'}}>
            <Toolbar>
                <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleSidebar}
                    sx={{mr: 2}}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography className={'custom-pointer'} variant="h6" component="div" sx={{flexGrow: 1}}>
                    {title}
                </Typography>
                <div style={{flexGrow: 1}}>
                    <img src={logo} alt="GGPAY Logo" style={{height: '30px', width: 'auto'}}/>
                </div>
                <div>
                    <Button color="inherit" onClick={() => pathHandler('/myInfo')}>내 정보</Button>
                    <Button color="inherit" onClick={() => pathHandler('/changePw')}>비밀번호 변경</Button>
                    <Button color="inherit" onClick={logoutHandler}>로그아웃</Button>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
