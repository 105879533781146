import React, {useEffect, useState} from 'react'
import {
    Box,
    Grid,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import {MonthNavigator} from "../home/components/MonthNavigator";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {memberState} from "../../store/memberStore";
import axios from '../../axiosInstance';
import {titleState} from "../../store/titleStore";
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import {loadingState} from "../../store/loadingStore";
import {utils, writeFile} from "xlsx";
import download from "../../images/download.png";
import {pageSetState} from "../../store/pageSetStore";
import SettingsIcon from '@mui/icons-material/Settings';
import PayDate from "../PayList/components/PayDate";
import NotiDate from "../PayList/components/NotiDate"; // 한국어 로케일

dayjs.locale('ko'); // dayjs를 한국어 로케일로 설정

export default function Statistics() {
    const pageSet = useRecoilValue(pageSetState);
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 2;
    const memberInfo = useRecoilValue(memberState);
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState({});
    const [tableValue, setTableValue] = useState(data.rank <= 1 ? 'LV2' : data.rank <= 2 ? 'LV3' : data.rank <= 3 ? 'LV4' : data.rank <= 4 ? 'LV5' : 'LV6');
    const [selectedDate, setSelectedDate] = useState(() => {
        const initialMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        const initialYear = currentMonth === 1 ? currentYear - 1 : currentYear;
        return `${initialYear}${initialMonth.toString().padStart(2, '0')}`;
    });
    const setTitle = useSetRecoilState(titleState);
    const [chargeTitle, setChargeTitle] = useState('')
    const [payDate, setPayDate] = useState({});
    const [notiDate, setNotiDate] = useState({previousMonthEnd: '', currentMonthEnd: ''});
    const [totalAmount, setTotalAmount] = useState(0);
    const setLoadingState = useSetRecoilState(loadingState);
    const [amtValue, setAmtValue] = useState('')
    const [isDateEdit, setIsDateEdit] = useState('none');
    const [totalFinalAmt, setTotalFinalAmt] = useState(0)
    const [totalOriginAmt, setTotalOriginAmt] = useState(0)

    useEffect(() => {
        getMonthResult();
        setTitle('월 정산')

    }, []);

    useEffect(() => {
        setIsDateEdit('none');
        getMonthResult();
        if (selectedDate) {
            getPayDate()
            getNotiDate()
        }
    }, [selectedDate]);


    useEffect(() => {
        handleChargeChange(tableValue);
    }, [tableValue, data.payResult]);

    useEffect(() => {
        getMonthResult();
    }, [tableValue]);

    useEffect(() => {
        setTotalFinalAmt(data?.notiResult?.FINAL_AMT);
        setTotalOriginAmt(data?.notiResult?.ORIGIN_AMT);
    }, [data]);


    useEffect(() => {
        if (tableData && tableValue) {
            // tableData의 모든 row에서 {tableValue}_AMT의 합계를 계산
            const total = tableData.reduce((sum, table) => {
                const value = table[`${amtValue}_AMT`] || 0; // 값이 없으면 0으로 처리
                return sum + value;
            }, 0);

            // 상태값 업데이트
            setTotalAmount(total);
        }
    }, [tableData, amtValue, tableValue]);

    const getPayDate = () => {
        axios.get(`/api/dateSet/${selectedDate}/payDate`).then((response) => {
            setPayDate({
                startDate: formatDate(response.data.START_DATE),
                endDate: formatDate(response.data.LAST_DATE),
            })
        }).catch(() => {
            const yearMonth = dayjs(selectedDate, 'YYYYMM');

            // 해당 월의 첫날과 마지막 날 구하기
            const firstDayOfMonth = yearMonth.startOf('month').format('M월 D일');
            let lastDayOfMonth = yearMonth.endOf('month').format('M월 D일');

            // 오늘 날짜 구하기
            const today = dayjs();

            // 마지막 날이 오늘 날짜 이후이면 오늘 날짜로 설정
            if (yearMonth.endOf('month').isAfter(today)) {
                lastDayOfMonth = today.format('M월 D일');
            }

            // setPayDate로 상태 업데이트
            setPayDate({
                startDate: firstDayOfMonth,
                endDate: lastDayOfMonth,
            });
        });
    }

    const getNotiDate = () => {

        axios.get(`/api/dateSet/${selectedDate}/notiDate`).then((response) => {
            setNotiDate({
                startDate: formatDate(response.data.START_DATE),
                endDate: formatDate(response.data.LAST_DATE),
            })

        }).catch(() => {
            const yearMonth = dayjs(selectedDate, 'YYYYMM');

            // 해당 월의 첫날과 마지막 날 구하기
            const firstDayOfMonth = yearMonth.startOf('month').format('M월 D일');
            let lastDayOfMonth = yearMonth.endOf('month').format('M월 D일');

            // 오늘 날짜 구하기
            const today = dayjs();

            // 마지막 날이 오늘 날짜 이후이면 오늘 날짜로 설정
            if (yearMonth.endOf('month').isAfter(today)) {
                lastDayOfMonth = today.format('M월 D일');
            }


            // setNotiDate로 상태 업데이트
            setNotiDate({
                startDate: firstDayOfMonth,
                endDate: lastDayOfMonth,
            });


        });

    }

    const formatDate = (dateString) => {
        // 날짜 문자열을 dayjs 객체로 변환
        const date = dayjs(dateString, 'YYYYMMDD');

        // 원하는 형식으로 변환
        return date.format('MM월 DD일');
    };

    const getMonthResult = async () => {
        await setLoadingState(true);

        await axios.get(`/api/pay/month/${memberInfo.memCode}?date=${selectedDate}&level=${tableValue}`).then((response) => {
            setTableData(response.data.payResult);
            setData(response.data);
        }).catch(() => {

        });

        await setLoadingState(false);
    }


    const handleChange = (event, newValue) => {
        setTableValue(newValue)
        handleChargeChange(newValue);
    };

    const handleChargeChange = (rank) => {

        switch (rank) {
            case 'LV2':
                setChargeTitle('대행사 수수료');
                setAmtValue('LV3')
                break;
            case 'LV3':
                setChargeTitle('지사 수수료');
                setAmtValue('LV4')
                break;
            case 'LV4':
                setChargeTitle('총판 수수료');
                setAmtValue('LV5')
                break;
            case 'LV5':
                setChargeTitle('대리점 수수료');
                setAmtValue('LV6')
                break;
            case 'LV6':
                setChargeTitle('총 수수료');
                setAmtValue('CHARGE')
                break;
        }

    }

    const getExcelData = () => {

        const data = tableData;
        // 엑셀 파일의 제목 행을 생성합니다.
        const headers = [
            '대행사', '지사', '총판', '대리점', '가맹점', '승인', '취소', 'PG 수수료',
            '본사 수수료', '대행사 수수료', '지사 수수료', '총판 수수료', '대리점 수수료', '총 수수료',
            '승인금액', '정산금액'
        ];
        // 데이터 행을 생성합니다.
        const rows = data.map((item) => [
            item.LV2_NAME, item.LV3_NAME, item.LV4_NAME, item.LV5_NAME, item.LV6_NAME, item.COUNT_N, item.COUNT_Y,
            item.PG_AMT, item.LV2_AMT, item.LV3_AMT, item.LV4_AMT, item.LV5_AMT, item.LV6_AMT, item.CHARGE_AMT,
            item.ORIGIN_AMT, item.FINAL_AMT
        ]);
        // 제목 행과 데이터 행을 합쳐 완성된 엑셀 파일을 생성합니다.
        const ws = utils.json_to_sheet([headers, ...rows], {skipHeader: true});
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");
        // 오늘 날짜를 yyyy-MM-dd 형식으로 변환합니다.
        const today = dayjs().format('YYYY-MM');
        // 파일 이름을 지정합니다.
        const filename = `${pageSet} ${today} 월 정산.xlsx`;
        writeFile(wb, filename);
    }


    return (
        <>
            <Grid container spacing={2}>
                {/* 첫 번째 줄 */}
                <Grid item xs={12} sm={2}>
                    <Paper elevation={3} sx={{
                        height: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        borderRadius: 2
                    }}>
                        <MonthNavigator currentMonth={currentMonth} currentYear={currentMonth}
                                        setSelectedDate={setSelectedDate} selectedDate={selectedDate}
                                        today={today} setTableData={setTableData}></MonthNavigator>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={10}>
                    <Paper elevation={3} sx={{
                        height: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        borderRadius: 2
                    }}>
                        {!data.payResult ? (
                            <Typography color="textSecondary">
                                {currentMonth + '월 '}승인내역이 없습니다.
                            </Typography>
                        ) : (
                            isDateEdit === 'none' ?
                                <>
                                    <Box textAlign="center" my={2}> {/* 전체 텍스트 가운데 정렬 및 마진 */}
                                        <Stack direction="row" spacing={4}
                                               justifyContent="center"> {/* 가로 정렬 및 간격 */}
                                            <Typography variant="h6" sx={{ fontSize: '17px' }} component="span">
                                                {'결제 정산 기간 : ' + payDate.startDate + ' ~ ' + payDate.endDate + ' '}
                                                {memberInfo.memRole === '본사' &&
                                                    <SettingsIcon
                                                        sx={{
                                                            ":hover": {cursor: 'pointer'},
                                                            marginBottom: '4px'
                                                        }}
                                                        onClick={() => {
                                                            setIsDateEdit('payDate')
                                                        }}
                                                    />
                                                }
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontSize: '17px' }} component="span">
                                                {'정산 / 결제 금액 : ' + (totalFinalAmt ? totalFinalAmt.toLocaleString() : 0) + ' 원 / ' + (totalOriginAmt ? totalOriginAmt.toLocaleString() : 0) + ' 원'}
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontSize: '17px' }} component="span">
                                                {'결제 승인 기간: ' + notiDate.startDate + ' ~ ' + notiDate.endDate + ' '}
                                                {memberInfo.memRole === '본사' &&
                                                    <SettingsIcon
                                                        sx={{
                                                            ":hover": {cursor: 'pointer'},
                                                            marginBottom: '4px'
                                                        }}
                                                        onClick={() => {
                                                            setIsDateEdit('notiDate')
                                                        }}
                                                    />
                                                }
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontSize: '17px' }} component="span">
                                                {'승인 : ' + data?.notiResult?.COUNT_N?.toLocaleString() + ' 건'}
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontSize: '17px' }} component="span">
                                                {'취소 : ' + data?.notiResult?.COUNT_Y?.toLocaleString() + ' 건'}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </>
                                :
                                isDateEdit === 'payDate' ?
                                    <PayDate getPayDate={getPayDate} selectedDate={selectedDate} setIsDateEdit={setIsDateEdit}/>
                                    :
                                    <NotiDate getNotiDate={getNotiDate} selectedDate={selectedDate} setIsDateEdit={setIsDateEdit}/>
                        )}

                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12}>
                    <Paper elevation={3}
                           sx={{padding: 2, borderRadius: 2, height: '100%', minHeight: '1000px'}}>
                        <Box sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Tabs value={tableValue} onChange={handleChange} aria-label="basic tabs example">
                                {memberInfo.roleRank <= 2 && <Tab value={'LV2'} label="대행사"/>}
                                {memberInfo.roleRank <= 3 && <Tab value={'LV3'} label="지사"/>}
                                {memberInfo.roleRank <= 4 && <Tab value={'LV4'} label="총판"/>}
                                {memberInfo.roleRank <= 5 && <Tab value={'LV5'} label="대리점"/>}
                                <Tab value={'LV6'} label="가맹점"/>
                            </Tabs>
                            <Box>
                                <Typography
                                    variant="body1">
                                {!(memberInfo.roleRank < 6 && tableValue === 'LV6') && (
                                    (chargeTitle + ' : ' + totalAmount.toLocaleString()
                                        + ' 원 / 총 수수료 : '
                                        + (data.sum ? parseInt(data.sum).toLocaleString() : 0) + ' 원')

                                )}
                                    {(memberInfo.roleRank < 2 && tableValue === 'LV6') &&
                                        <img onClick={getExcelData}
                                             style={{cursor: 'pointer', marginLeft: 10, marginTop: 0}}
                                             src={download} width={'20px'} alt={'download img'}/>
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{maxHeight: '1000px', overflowY: 'auto'}}> {/* 스크롤바 적용을 위한 스타일 */}
                            <Table>
                                <TableHead>
                                    <TableRow sx={{backgroundColor: 'grey.200'}}>
                                        {memberInfo.roleRank < 2 && (tableValue === 'LV6' || tableValue === 'LV5' || tableValue === 'LV4' || tableValue === 'LV3' || tableValue === 'LV2') && (
                                            <TableCell align="center" component="th">
                                                대행사
                                            </TableCell>
                                        )}
                                        {memberInfo.roleRank < 3 && (tableValue === 'LV6' || tableValue === 'LV5' || tableValue === 'LV4' || tableValue === 'LV3') && (
                                            <TableCell align="center" component="th">
                                                지사
                                            </TableCell>
                                        )}
                                        {memberInfo.roleRank < 4 && (tableValue === 'LV6' || tableValue === 'LV5' || tableValue === 'LV4') && (
                                            <TableCell align="center" component="th">
                                                총판
                                            </TableCell>
                                        )}
                                        {memberInfo.roleRank < 5 && (tableValue === 'LV6' || tableValue === 'LV5') && (
                                            <TableCell align="center" component="th">
                                                대리점
                                            </TableCell>
                                        )}
                                        {(memberInfo.roleRank < 6 && tableValue === 'LV6') && (
                                            <TableCell align="center" component="th">
                                                가맹점
                                            </TableCell>
                                        )}
                                        <TableCell align="center" component="th">승인</TableCell>
                                        <TableCell align="center" component="th">취소</TableCell>
                                        {(memberInfo.role === 'ROLE_ADMIN' && memberInfo.roleRank < 6 && tableValue === 'LV6') && (
                                            <>
                                                <TableCell align="center" component="th">
                                                    PG 수수료
                                                </TableCell>
                                                <TableCell align="center" component="th">
                                                    본사 수수료
                                                </TableCell>
                                                <TableCell align="center" component="th">
                                                    대행사 수수료
                                                </TableCell>
                                                <TableCell align="center" component="th">
                                                    지사 수수료
                                                </TableCell>
                                                <TableCell align="center" component="th">
                                                    총판 수수료
                                                </TableCell>
                                                <TableCell align="center" component="th">
                                                    대리점 수수료
                                                </TableCell>
                                                <TableCell align="center" component="th">
                                                    총 수수료
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell align="center" component="th">승인금액</TableCell>
                                        <TableCell align="center" component="th">정산금액</TableCell>
                                        {!(memberInfo.roleRank < 6 && tableValue === 'LV6') &&
                                            <>
                                                {tableValue !== 'LV5' &&
                                                    <TableCell align="center" component="th">총 수수료</TableCell>
                                                }
                                                <TableCell align="center"
                                                           component="th">{chargeTitle}</TableCell>
                                            </>

                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData && tableData.map((table, index) => (
                                        (table.MEM_ID !== '' && table.MEM_ID !== undefined) &&
                                        <TableRow key={index}
                                                  sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.04)'}}}>
                                            {memberInfo.roleRank < 2 && (tableValue === 'LV6' || tableValue === 'LV5' || tableValue === 'LV4' || tableValue === 'LV3' || tableValue === 'LV2') && (
                                                <TableCell align="center">{table.LV2_NAME}</TableCell>
                                            )}
                                            {memberInfo.roleRank < 3 && (tableValue === 'LV6' || tableValue === 'LV5' || tableValue === 'LV4' || tableValue === 'LV3') && (
                                                <TableCell align="center">{table.LV3_NAME}</TableCell>
                                            )}
                                            {memberInfo.roleRank < 4 && (tableValue === 'LV6' || tableValue === 'LV5' || tableValue === 'LV4') && (
                                                <TableCell align="center">{table.LV4_NAME}</TableCell>
                                            )}
                                            {memberInfo.roleRank < 5 && (tableValue === 'LV6' || tableValue === 'LV5') && (
                                                <TableCell align="center">{table.LV5_NAME}</TableCell>
                                            )}
                                            {(memberInfo.roleRank < 6 && tableValue === 'LV6') && (
                                                <TableCell align="center">{table.LV6_NAME}</TableCell>
                                            )}
                                            <TableCell
                                                align="center">{table.COUNT_N && table.COUNT_N.toLocaleString()}</TableCell>
                                            <TableCell
                                                align="center">{table.COUNT_Y && table.COUNT_Y.toLocaleString()}</TableCell>
                                            {(memberInfo.role === 'ROLE_ADMIN' && memberInfo.roleRank < 6 && tableValue === 'LV6') && (
                                                <>
                                                    <TableCell align="center" component="th">
                                                        {table.PG_AMT && table.PG_AMT.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center" component="th">
                                                        {table.LV2_AMT && table.LV2_AMT.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center" component="th">
                                                        {table.LV3_AMT && table.LV3_AMT.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center" component="th">
                                                        {table.LV4_AMT && table.LV4_AMT.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center" component="th">
                                                        {table.LV5_AMT && table.LV5_AMT.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center" component="th">
                                                        {table.LV6_AMT && table.LV6_AMT.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center" component="th">
                                                        {table.CHARGE_AMT && table.CHARGE_AMT.toLocaleString()}
                                                    </TableCell>
                                                </>
                                            )}

                                            <TableCell
                                                align="center">{table.ORIGIN_AMT && table.ORIGIN_AMT.toLocaleString()}</TableCell>
                                            <TableCell
                                                align="center">{table.FINAL_AMT && table.FINAL_AMT.toLocaleString()}</TableCell>
                                            {!(memberInfo.roleRank < 6 && tableValue === 'LV6') &&
                                                <>
                                                    {tableValue !== 'LV5' &&
                                                        <TableCell
                                                            align="center">{table.TOTAL_LV_AMT && table.TOTAL_LV_AMT.toLocaleString()}</TableCell>
                                                    }
                                                    <TableCell align="center">
                                                        {table[`${amtValue}_AMT`] && table[`${amtValue}_AMT`].toLocaleString()}
                                                    </TableCell>
                                                </>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </>
    )
}
