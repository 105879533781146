import {
    ThemeProvider,
    Button,
    Checkbox,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography, Pagination, PaginationItem
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from '../../axiosInstance';
import {createTheme} from "@mui/material/styles";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {titleState} from "../../store/titleStore";
import SearchPR from "./components/SearchPR";
import {colorState} from "../../store/colorStore";
import dayjs from "dayjs";
import {loadingState} from "../../store/loadingStore";

const tableTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    // 모든 TableCell 컴포넌트에 적용될 패딩 값 설정
                    padding: '6px',
                    fontFamily: 'Pretendard-Regular',
                },
            },
        },
    },
});

export const PayResult = () => {

    const setLoadingState = useSetRecoilState(loadingState);
    const [data, setData] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [filter, setFilter] = useState('N');
    const setTitle = useSetRecoilState(titleState);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [initState, setInitState] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [countStartNum, setCountNum] = useState()
    const [sumData, setSumData] = useState({})
    const [isComp, setIsComp] = useState(false);
    const color = useRecoilValue(colorState);
    const [searchKeyword, setSearchKeyword] = useState({
        searchWord: '',
        searchCategory: 'catId',
        startDate: dayjs().tz().subtract(1, 'day').format('YYYYMMDD'),
        endDate: '',
        selectedMem: ''
    });
    useEffect(() => {
        const number = Number(pageData.total) - ((Number(pageData.currentPage) - 1) * Number(pageData.pageSize));
        setCountNum(number);
    }, [pageData]);

    useEffect(() => {
        // getPayResult();
        setTitle('가맹점 정산(정산대기)')
    }, [])

    useEffect(() => {
        if (isSearch || initState) {

        }
        // getPayResult();
    }, [filter])

    useEffect(() => {
        setCheckList([]);
        setIsAllChecked(false);
        if (isSearch || initState) {

        }
        // getPayResult();
    }, [page]);


    useEffect(() => {
        // 전체 선택 체크박스 상태에 따라 모든 항목을 체크하거나 체크 해제
        if (isAllChecked) {
            setCheckList(data.map(item => item.RS_ID));
        } else {
            setCheckList([]);
        }
    }, [isAllChecked, data]);

    const handleCheckboxChange = (event, catId) => {
        if (event.target.checked) {
            setCheckList(prev => [...prev, catId]);
        } else {
            setCheckList(prev => prev.filter(item => item !== catId));
        }
    };

    const handleSelectAllChange = (event) => {
        setIsAllChecked(event.target.checked);
    };


    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const changeTarget = (event) => {
        const title = event.target.value === 'ALL' ? '전체' : event.target.value === 'N' ? '정산대기' : '정산완료';

        setFilter(event.target.value);
        setTitle('가맹점 정산 (' + title + ')')
        setPage(1);
    }

    const updateResult = async (event) => {
        await setLoadingState(true);

        const payload = [...checkList];

        if (!payload || payload.length === 0) {
            return;
        }

        await axios.patch('/api/pay/update', payload).then(() => {
            search();
        }).catch(() => {
                alert('실패하였습니다.');
            }
        );
        await setLoadingState(false);
    }


    const dailyResult = async () => {
        await setLoadingState(true);

        await axios.get('/api/admin/duplicatePayResult').then((response) => {
            if (response.data > 0) {
                alert('중복된 데이터가 있습니다. 확인 후 다시 시도해주세요.')

            } else {
                axios.get('/api/admin/daily').then((response) => {
                    search();
                    alert('정산데이터 생성이 완료되었습니다.')
                });
            }
        });
        await setLoadingState(false);

    }


    const search = async () => {
        await setLoadingState(true);

        setIsComp(filter === 'N' ? false : true);
        await axios.post(`/api/pay/result?comp=${filter}&page=${page}`, searchKeyword).then(response => {
            const data = {...response.data};
            setData(data.content);
            setPageData(data);
            setIsSearch(true);
        });

        await axios.post(`/api/pay/sumResult?comp=${filter}`, searchKeyword).then(response => {
            const data = [...response.data];
            const columns = ["LV4_AMT", "ORIGIN_AMT", "LV5_AMT", "LV2_AMT", "FINAL_AMT", "LV3_AMT", "PG_AMT", "LV6_AMT", "CHARGE_AMT"];
            const totals = columns.reduce((acc, column) => {
                acc[column] = data.reduce((sum, item) => {
                    return sum + parseInt(item[column].replace(/,/g, ''), 10);
                }, 0);
                return acc;
            }, {});

            const formattedTotals = Object.keys(totals).reduce((acc, key) => {
                acc[key] = totals[key].toLocaleString();
                return acc;
            }, {});
            setSumData(formattedTotals);

        });

        await setLoadingState(false);

    }

    return (
        <ThemeProvider theme={tableTheme}>
            <Typography variant="h4" align="center" gutterBottom>
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Button onClick={dailyResult} value={'ALL'} variant="contained" size="small"
                            sx={{marginX: 1, backgroundColor: color, '&:hover': {backgroundColor: '#333'}}}>
                        정산 리스트 생성
                    </Button>
                    {/*<Button onClick={updateAllResult} value={'ALL'} variant="contained" size="small"*/}
                    {/*        sx={{marginX: 1, backgroundColor: '#004E8A', '&:hover': {backgroundColor: '#333'}}}>*/}
                    {/*    일괄정산*/}
                    {/*</Button>*/}
                    <Button onClick={updateResult} variant="contained" size="small"
                            sx={{backgroundColor: color, '&:hover': {backgroundColor: '#333'}}}>
                        선택 정산
                    </Button>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" sx={{paddingTop: 2}}>
                <SearchPR search={search} setIsComp={setIsComp} changeTarget={changeTarget} filter={filter}
                          setSearchKeyword={setSearchKeyword} searchKeyword={searchKeyword} setInitState={setInitState}
                          initState={initState}
                          isSearch={isSearch} setIsSearch={setIsSearch} page={page} setPage={setPage}
                ></SearchPR>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650, marginTop: 1}} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{backgroundColor: 'grey.200'}}>
                            <TableCell align="center" component="th">
                                No
                            </TableCell>
                            <TableCell align="center" component="th" sx={{maxHeight: '50px'}}>
                                <Checkbox
                                    size="small"
                                    checked={isAllChecked}
                                    indeterminate={checkList.length > 0 && checkList.length < data.length}
                                    onChange={handleSelectAllChange}
                                    inputProps={{'aria-label': 'select all'}}
                                    sx={{
                                        color: 'black',
                                        '&.Mui-checked': {
                                            color: 'black',
                                        },
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center" component="th">
                                터미널
                            </TableCell>
                            <TableCell align="center" component="th">
                                승인일
                            </TableCell>
                            <TableCell align="center" component="th">
                                승인건
                            </TableCell>
                            <TableCell align="center" component="th">
                                취소건
                            </TableCell>
                            <TableCell align="center" component="th">
                                PG
                            </TableCell>
                            <TableCell align="center" component="th">
                                가맹정점명
                            </TableCell>

                            {/*<TableCell align="center" component="th">*/}
                            {/*    PG <br/>*/}
                            {/*    {sumData.PG_AMT}*/}
                            {/*</TableCell>*/}
                            {/*<TableCell align="center" component="th">*/}
                            {/*    본사 <br/>*/}
                            {/*    {sumData.LV2_AMT}*/}
                            {/*</TableCell>*/}
                            {/*<TableCell align="center" component="th">*/}
                            {/*    대행사 <br/>*/}
                            {/*    {sumData.LV3_AMT}*/}
                            {/*</TableCell>*/}
                            {/*<TableCell align="center" component="th">*/}
                            {/*    지사 <br/>*/}
                            {/*    {sumData.LV4_AMT}*/}
                            {/*</TableCell>*/}
                            {/*<TableCell align="center" component="th">*/}
                            {/*    총판 <br/>*/}
                            {/*    {sumData.LV5_AMT}*/}
                            {/*</TableCell>*/}
                            {/*<TableCell align="center" component="th">*/}
                            {/*    대리점 <br/>*/}
                            {/*    {sumData.LV6_AMT}*/}
                            {/*</TableCell>*/}
                            {isComp &&
                                <TableCell align="center" component="th">
                                    정산일자
                                </TableCell>
                            }
                            <TableCell align="center" component="th">
                                승인금액 <br/>
                                {sumData.ORIGIN_AMT}
                            </TableCell>
                            <TableCell align="center" component="th">
                                정산금액 <br/>
                                {sumData.FINAL_AMT}
                            </TableCell>
                            <TableCell align="center" component="th">
                                총 수수료 <br/>
                                {sumData.CHARGE_AMT}
                            </TableCell>
                            <TableCell align="center" component="th">
                                은행
                            </TableCell>
                            <TableCell align="center" component="th">
                                계좌번호
                            </TableCell>
                            <TableCell align="center" component="th">
                                정산
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length !== 0 &&
                            data.map((data, idx) => (
                                <TableRow key={`${data.RS_ID}-${idx}`} align="center" sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // 호버 시 원하는 색상으로 변경
                                    },
                                }}>
                                    <TableCell align="center" scope="row">
                                        {countStartNum - idx}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Checkbox
                                            size="small"
                                            checked={checkList.includes(data.RS_ID)}
                                            onChange={(e) => handleCheckboxChange(e, data.RS_ID)}
                                            sx={{
                                                color: 'black',
                                                '&.Mui-checked': {
                                                    color: 'black',
                                                },
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CAT_ID}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.DATE}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.COUNT_N}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.COUNT_Y}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.PG_NAME}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.MEM_NAME}
                                    </TableCell>
                                    {isComp &&
                                        <TableCell align="center">
                                            {data.COMP_DATE}
                                        </TableCell>}
                                    {/*<TableCell align="center">*/}
                                    {/*    {data.PG_AMT}*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell align="center">*/}
                                    {/*    {data.LV2_AMT}*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell align="center">*/}
                                    {/*    {data.LV3_AMT}*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell align="center">*/}
                                    {/*    {data.LV4_AMT}*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell align="center">*/}
                                    {/*    {data.LV5_AMT}*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell align="center">*/}
                                    {/*    {data.LV6_AMT}*/}
                                    {/*</TableCell>*/}
                                    <TableCell align="center">
                                        {data.ORIGIN_AMT}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.FINAL_AMT}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.CHARGE_AMT}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.BANK_NAME}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.BANK_NUM}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data.IS_COMP === 'Y' ? '정산완료' : '미완료'}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <Grid item xs={12}>
                    <Pagination
                        count={pageData.pageSize > 0 ? Math.ceil(pageData.total / pageData.pageSize) : 0}
                        page={pageData.currentPage || 1} // pageData.page가 undefined일 경우 기본값 1 사용
                        onChange={onPageChange}
                        size="medium"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "15px 0",
                        }}
                        renderItem={(item, idx) => (
                            <PaginationItem key={idx} {...item} sx={{fontSize: 12}}/>
                        )}
                    />
                </Grid>
            </TableContainer>
        </ThemeProvider>
    )
}
